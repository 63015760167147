exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-competitions-js": () => import("./../../../src/pages/competitions.js" /* webpackChunkName: "component---src-pages-competitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onboard-js": () => import("./../../../src/pages/onboard.js" /* webpackChunkName: "component---src-pages-onboard-js" */),
  "component---src-pages-personalize-js": () => import("./../../../src/pages/personalize.js" /* webpackChunkName: "component---src-pages-personalize-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */)
}

